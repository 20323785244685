
<template>
   <div class="custom-select" 
        v-if="list"
        :class="{
            'custom-select_type_error':error,
            'custom-select_type_focus':isFocus && !isload,
            'custom-select_type_disabled':disabled || isload
        }"
        v-click-outside="()=>{isFocus=false}"   
    >
        <label v-if="label" class="custom-select__label">
            {{label}}
        </label>
        <div class="custom-select__input"
            tabindex="0"
            @click.prevent="()=>{ isFocus=(!isFocus && !$props.disabled) }"
            @keydown = "handleInputKeyUp"
        > 
            <div class="custom-select__input-selector"
                :class="{
                    'custom-select__input-placeholder':!selected
                }"
            >
                <template v-if="selected">
                    {{selected}}
                </template>
                <template v-else>
                    {{placeholder}}
                </template>
            </div>

            <button tabindex="-1" type="button" class="custom-select__button button_type_static button_type_icon"
                v-if="buttonIcon"
                :class="buttonIconClass"
                @click.stop.prevent = "handleClick($event)"
            >
                <svg aria-hidden="true" v-if="buttonIcon" :width="iconSize.w" :height="iconSize.h">
                    <use :href="buttonIcon"></use>
                </svg>
            </button>
            
            <v-button tabindex="-1" class="custom-select__button button_type_static" 
                :class="{'is-active':isFocus && !isload}"
                :disabled="disabled"
                :loading="isload"
                @click.stop.prevent="handleVButtonClick"
            >
                <svg aria-hidden="true" width="16" height="16">
                    <use href="#icon-arrow-down"></use>
                </svg>
            </v-button>
            <v-list 
                ref="list"
                class="dropList_size_full-width"
                :list="list" 
                :expand="isFocus && !disabled && !isload" 
                :selected = "selectedItem"
                @click="handleClickList($event)"/>  
        </div>
        <transition name="grow">
            <div v-if="error" class="validation__label validation__label_type_error">
                {{ error }}
            </div>
        </transition>
    </div>
</template>

<script>
import vList from './DropDownList.vue' 
import vButton from './vButton.vue'

export default {
    name:"vCustomSelect",
    props:{
        buttonIcon: {
            type: String,
            default: ''
        },
        buttonIconClass: { 
            type: Object,
            default: () => {}
        },
        iconSize: {
            type: Object,
            default: function() {
                return { w: 20, h: 20 };
            }
        },
        placeholder: {
            type: String,
            default: ''
        },
        name:  {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        error: {
            type: [String,Boolean],
            default: ''
        },
        list: {
            type: [Array, String],
            required: false,
            default: () => []
        },
        value:{
            default: ''
        },
        disabled:{
            type: Boolean,
            default: false
        },
        propSelected: {
            type: Object,
            required: false,
            default: () => {}
        },
        isload: {
            type: Boolean,
            default: false
        }
        
    },
    components:{
        vList,
        vButton
    },
    data() {
        return {
            isFocus: false,
            selectedItem: false,
        }
    },
    methods: {
        handleClickList(event) {
            this.$emit('input', event.id)
            this.$emit('update', event)
        },
        handleClick(event) {
            this.$emit('buttonClick')
        },
        handleVButtonClick() {
            if(this.isload) return
            this.isFocus = !this.isFocus

        },
        handleInputKeyUp(event) {
            const list = this.$refs.list
            switch (event.code) {
                case 'Enter':
                    if(!this.isFocus) {
                        this.isFocus = true
                        return
                    }
                    list.hanldeKeyEnter()
                    break;
                case 'Space':
                    this.isFocus = true
                    break;
                case 'Escape':
                    this.isFocus = false
                    break;
                case 'ArrowDown':
                    if(this.isFocus) {
                        list.HandleUpDown()
                        event.preventDefault();
                    }
                    break
                case 'ArrowUp':
                    if(this.isFocus) {
                        list.HandleUpArrow()
                        event.preventDefault();
                    }
                    break
            }
        },
    },
    computed: {
        selected() {
            if(!this.value || (this.disabled && !this.value)) return this.value
            let selected = this.propSelected? this.propSelected.text || this.propSelected.name : ''
            this.list.some(item=>{
                if(item.id == this.value){
                    selected = item.name || item.text
                    this.selectedItem = item
                    return true
                }
            })
            return ''+selected //чтобы 0 выводился
        }
    }
}
</script>