import api from '@api'
export default {
    state: { 
        departments_list: null,
        tab: 'all',
        fastphrase: null
    },
    mutations: {
        CHAT_REFERENCES(state,data) { 
            state.departments_list = data.departments_list
        },
        SET_TAB(state, data) {
            state.tab = data
        },
        SET_FAST_PHRASE(state,data) { 
            state.fastphrase = data
        },
        RESET(state) {
            state.departments_list = null
            state.tab = 'all'
            state.fastphrase = null
        },
        RESET_TAB(state) {
            state.tab = 'all'
        }
    },
    actions: {
        GET_CHAT_DEPARTMENTLIST({commit}){
            return new Promise( (resolve, reject) => {
                api.chat.getReferences().then(res => {
                    commit('CHAT_REFERENCES', res)
                    resolve(res)
                }).catch(err=>{
                    console.error(err)
                })
            })
        },
        GET_CHAT_FASTPHRASE({commit,state},id) {
            return new Promise( (resolve, reject) => {
                if(state.fastphrase) {
                    resolve(state.fastphrase)
                }
                else {
                    
                    api.chat.quickPhrase('',id).then(res => {
                        commit('SET_FAST_PHRASE', res.response)
                        resolve(res.response)
                    }).catch(err=>{
                        console.error(err)
                    })
                }
            })
        },
        UPDATE_CHAT_FASTPHRASE({commit,state}) {
            return api.chat.quickPhrase().then(res => {
                commit('SET_FAST_PHRASE', res.response)
                return res.response
            }).catch(err=>{
                console.error(err)
            })
        },
        TOGGLE_CHAT_TAB({commit}, data) {
            commit('SET_TAB', data)
        },
        RESET_CHATS({commit, state}) {
            commit('RESET')
        },
        RESET_TAB({commit}) {
            commit('RESET_TAB')
        }
    },
    getters: {
        departments_list: state => state.departments_list,
        chat_tab:  state => state.tab,
        fastphrase: state => state.fastphrase
    }
}