export default function (url) {
  let options = { domen:'new.fleetsoft.pro', bgcolor: '#0f0' }
  const regex = /new\.fleetsoft\.pro/;
  const testregex = /test-new\.fleetsoft\.pro/;
  const devregex = /dev-new\.fleetsoft\.pro/;
  // options = { domen:'test-new.fleetsoft.pro', bgcolor: '#f00'}
  // options = { domen:'back-new.fleetsoft.pro', bgcolor: '#f00'}
  
   options = { domen:'slipkovskyi.fleetsoft.pro', bgcolor: '#f00'}
  // options = { domen:'dev-new.fleetsoft.pro', bgcolor: '#f00'}
  // options = { domen:'new.fleetsoft.pro', bgcolor: '#0f0' }
  // options = { domen:'dev-new2.fleetsoft.pro', bgcolor: '#f00'}
  
  // options = { domen:'new.fleetsoft.pro', bgcolor: '#0f0' }
  if (regex.test(window.location.href) && !testregex.test(window.location.href) && !devregex.test(window.location.href)) {
    options = { domen:'new.fleetsoft.pro', bgcolor: '#0f0' }
  }
  //console.log('%c'+options.domen,'background: '+options.bgcolor+'; color: #000; font-weight: 900; padding:5px')
  return 'https://'+options.domen+'/api' + url
}